@import '/src/styles/colours';
@import '/src/styles/mixins';

.wrapperInfo {
  width: 250px;
  height: 97%;
  z-index: 200;
  position: absolute;
  margin: 10px;
}

.infoParking {
  height: 100%;
  width: 100%;
  background-color: rgba(245, 245, 245, 0.9);
  border-radius: 10px;
  color: whitesmoke;
  box-shadow: 0px 0px 20px 6px $brand-dark;
}

.closeButton {
  position: absolute;

  top: 5px;
  right: 5px;

}

.img {
  display: flex;
  flex: 1;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.footer {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  background-color: $brand-light;
  color: $brand-dark;
  height: 9%;
  align-items: center;
  font-size: bold;
  

  button {
    background-color: $brand-light;
    display: flex;
    justify-content: center;
    align-items: center;
   
    border: 0px;
    &:hover {
      background-color: transparent !important;
      color: $brand-dark !important;
      border: transparent !important;
    }
  }

  div {
    margin: 0 auto;
  }
}

@include xxs-max {
  .wrapperInfo {
    width: 95%;
    margin-top: 0;
    margin-left: 0;
    margin: 5px;
  }
}
