@import '../../../../../styles/colours';
.header {
  display: flex;
  align-items: center;
  //background-color: $brand-dark;
  padding: 10px;
  color: $brand-light;
}

.navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  height: 40px;
  /*width: 200px;*/
  color: whitesmoke;
  margin-right: 10rem;
  p {
    font-size: 36px;
  }
  span {
    color: $brand-light;
    font-size: 36px;
  }
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  //color: $brand-dark;
  height: 40px;
  /*margin-left: 5rem;*/
  margin-right: 10rem;
  cursor: pointer;
}

.right {
  display: flex;
  align-items: center;

  button {
    background-color: transparent;
    border: 1px solid white;
    margin-right: 10px;

    &:hover {
      color: $brand-light !important;
      border: 1px solid $brand-light !important;
    }
  }
  // span {
  //   margin-right: 20px;
  //   color: white;
  //   border: 1px solid white;
  //   padding: 10px;
  //   border-radius: 10px;
  // }
}

.button {
  margin-right: 20px;
}
