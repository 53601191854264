@import '/src/styles/colours';
@import '/src/styles/mixins';

.scrollReview {
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  height: 51%;
  scroll-snap-type: y mandatory;
  margin-top: 3%;
}

@include lg-max {
  .scrollReview {
    height: 57%;
  }
}

@include sm-max {
  .scrollReview {
    height: 61%;
  }
}

@include xs-max {
  .scrollReview {
    height: 48%;
  }
}
